<template>
  <div>
    <form v-if="!generatedImage" class="adgenerator">
      <div class="adlayout" ref="printMe">
        <div class="mainimage secondary-color">
          <div class="ad-upload-wrap" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" @change="onChangeImage" ref="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display: none" />

            <label for="assetsFieldHandle" class="cursor-pointer" v-if="!filePreview">
              <span class="material-icons">backup</span>
              <h4 style="margin-top: 5px; margin-bottom: 0.5rem; font-size: 1.2rem">{{ $t("generator.image") }}</h4>
              <div style="max-width: 300px">{{ $t("ads.uploadInfo") }}</div>
            </label>

            <div class="preview-image" :style="{ backgroundImage: `url(${filePreview})` }" v-if="filePreview"></div>
          </div>
        </div>
        <div class="maincontent highlight-color">
          <textarea maxlength='70' v-model="generator.title" type="title" name="title" id="title" class="title" :placeholder="$t('generator.title')" />
        </div>
        <div class="infotext highlight-color">
          <textarea maxlength='120' v-model="generator.description" type="description" name="description" id="description" :placeholder="$t('generator.description')" />
        </div>
        <div class="logo highlight-color">
          <div class="ad-upload-wrap" @dragover="dragover" @dragleave="dragleave" @drop="dropLogo">
            <input type="file" @change="onChangeLogo" ref="fileLogo" name="fields[assetsFieldHandleLogo][]" id="assetsFieldHandleLogo" style="display: none" />

            <label for="assetsFieldHandleLogo" class="cursor-pointer" v-if="!filePreviewLogo">
              <span class="material-icons">backup</span>
              <h4 style="margin-top: 0; margin-bottom: 0.5rem; font-size: 1rem">{{ $t("generator.logo") }}</h4>
            </label>

            <div class="preview-image" :style="{ backgroundImage: `url(${filePreviewLogo})` }" v-if="filePreviewLogo"></div>
          </div>
        </div>
        <div class="coin-info secondary-color">
          <textarea maxlength='100' v-model="generator.coininfo" type="coininfo" name="coininfo" id="coininfo" :placeholder="$t('generator.coininfo')" />
        </div>
        <div class="price-info highlight-color">
          <textarea maxlength='100' v-model="generator.couponinfo" type="couponinfo" name="couponinfo" id="couponinfo" :placeholder="$t('generator.couponinfo')" />
        </div>
        <div class="qr-code secondary-color">
          <span class="qr-info"></span>
        </div>
      </div>
    </form>

    <p style="margin-top: 40px; text-align: right" v-if="!generatedImage">
      <a @click="print" class="button button-ci button-round button-100" style="cursor: pointer">{{ $t("generator.button") }}</a>
    </p>

    <div v-if="generatedImage">
      <br />
      <h2 style="font-weight: 600">{{ $t("generator.yours") }}:</h2>
      <br />

      <img :src="generatedImage" />
    </div>

    <div v-if="progress_bar" style="margin-bottom: 20px">
      <p style="display: block; font-size: 16px; margin-bottom: 8px; font-weight: 600; color: #91979f">{{ $t("ads.progress") }}:</p>

      <div class="progress-bar">
        <span class="progress" :style="'width:' + progress + '%'"></span>
        <p>{{ progress }} %</p>
      </div>
    </div>

    <p style="margin-top: 40px; text-align: right; display: flex; justify-content: space-between" v-if="generatedImage">
      <a style="margin-right: 10px" @click="reset_image" class="button button-gray button-round button-100">{{ $t("generator.edit") }}</a>

      <a @click="create_advertisment" class="button button-ci button-round button-100" style="cursor: pointer">{{ $t("generator.upload") }}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "AdGenerator",
  data() {
    return {
      filePreview: null,
      filePreviewLogo: null,
      progress_bar: false,
      generatedImage: null,
      generatedImageBlob: null,

      generator: {
        imageMain: null,
        imageLogo: null,
        title: "",
        description: "",
        coininfo: "",
        priceinfo: "",
        couponinfo: "",
      },
    };
  },
  methods: {
    async print() {
      document.querySelector("body").style.letterSpacing = "1.5px";
      document.querySelector("body").style.fontSize = "16px";
      document.querySelector("body").style.fontWeight = "100";

      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
      };
      this.generatedImage = await this.$html2canvas(el, options);
      fetch(this.generatedImage)
        .then((res) => res.blob())
        .then((blob) => (this.generatedImageBlob = blob));
      console.log(this.generatedImageBlob);

      document.querySelector("body").style.letterSpacing = "0.5px";
      document.querySelector("body").style.fontSize = "14px";
      document.querySelector("body").style.fontWeight = "400";
    },
    create_advertisment: function () {
      var that = this;

      if (this.generatedImage == null) {
        this.$notify({
          group: "notification",
          type: "error",
          text: "Bitte erst das Werbebild generieren.",
        });
      } else {
        var form_data = new FormData();
        form_data.append("asset_file", this.generatedImageBlob);
        form_data.append("name", this.generator.title);
        form_data.append("advertisment_type", "image");
        form_data.append("duration", 5);

        this.$http
          .post(process.env.VUE_APP_BASE_API + "/advertisments", form_data, {
            headers: { Authorization: that.$store.getters.getToken },
            progress(e) {
              that.progress_bar = true;
              if (e.lengthComputable) {
                that.progress = parseFloat((e.loaded / e.total) * 100).toFixed(2);
              }
            },
          })
          .then(
            (response) => {
              if (this.$route.query.campaign_uuid) {
                this.$router.push("/campaigns/"+this.$route.query.campaign_uuid+'?select_advertisment_uuid='+response.body.advertisment.uuid);
              } else {
                this.$router.push("/advertisments");
              }
            },
            (response) => {
              response.body.errors.forEach((value) => {
                this.$notify({
                  group: "notification",
                  type: "error",
                  text: value,
                });
              });
            }
          );
      }
    },

    generateOnServer: function () {
      var that = this;

      if (this.generator.imageMain == null) {
        this.$notify({
          group: "notification",
          type: "error",
          text: "Bitte Datei hochladen.",
        });
      } else {
        var form_data = new FormData();
        form_data.append("imageMain", this.generator.imageMain);
        form_data.append("imageLogo", this.generator.imageLogo);

        form_data.append("title", this.generator.title);
        form_data.append("description", this.generator.description);

        this.$http
          .post(process.env.VUE_APP_BASE_API + "/generatead", form_data, {
            headers: { Authorization: that.$store.getters.getToken },
            progress(e) {
              that.progress_bar = true;
              if (e.lengthComputable) {
                that.progress = parseFloat((e.loaded / e.total) * 100).toFixed(2);
              }
            },
          })
          .then(
            (response) => {
              this.$router.push("/advertisments");
              console.log(response);
            },
            (response) => {
              response.body.errors.forEach((value) => {
                this.$notify({
                  group: "notification",
                  type: "error",
                  text: value,
                });
              });
            }
          );
      }
    },
    onChangeImage() {
      let dropFiles = [...this.$refs.file.files];
      let firstImage = dropFiles[0];

      if (firstImage.type === "image/png" || firstImage.type === "image/jpeg") {
        this.filePreview = URL.createObjectURL(firstImage);
        this.generator.imageMain = firstImage;

        console.log(this.generator.imageMain);
      }
    },
    onChangeLogo() {
      let dropFiles = [...this.$refs.fileLogo.files];
      let firstImage = dropFiles[0];

      if (firstImage.type === "image/png" || firstImage.type === "image/jpeg") {
        this.filePreviewLogo = URL.createObjectURL(firstImage);
        this.generator.imageLogo = firstImage;
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green")) {
        event.currentTarget.classList.remove("bg-gray");
        event.currentTarget.classList.add("bg-green");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChangeImage(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },
    dropLogo(event) {
      event.preventDefault();
      this.$refs.fileLogo.files = event.dataTransfer.files;
      this.onChangeLogo(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },
    reset_image() {
      this.generatedImage = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@media (max-width: 1280px) {
  .adgenerator {
    overflow: scroll;
  }
}

.adlayout {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 1px;
  grid-row-gap: 1px;

  width: 1280px;
  height: 720px;
  background: black;
  padding: 3px;

  > div {
    margin: 2px;
  }
}

.mainimage {
  grid-area: 1 / 1 / 6 / 4;
}

.maincontent {
  grid-area: 1 / 4 / 3 / 7;
}
.infotext {
  grid-area: 3 / 4 / 4 / 6;
}
.logo {
  grid-area: 3 / 6 / 4 / 7;
}
.coin-info {
  grid-area: 4 / 4 / 5 / 6;
}
.price-info {
  grid-area: 5 / 4 / 6 / 6;
}
.coupon-info {
  grid-area: 5 / 5 / 6 / 6;
}
.qr-code {
  grid-area: 4 / 6 / 6 / 7;
}

@media (max-width: 768px) {
  .adlayout {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(10, 1fr);
    width: auto;
    height: auto;
  }

  .mainimage {
    grid-area: 1 / 1 / 6 / 4;
  }
  .maincontent {
    grid-area: 6 / 1 / 8 / 4;
  }
  .infotext {
    grid-area: 8 / 1 / 9 / 3;
  }
  .logo {
    grid-area: 8 / 3 / 9 / 4;
  }
  .coin-info {
    grid-area: 9 / 1 / 10 / 3;
  }
  .price-info {
    grid-area: 10 / 1 / 11 / 2;
  }
  .coupon-info {
    grid-area: 10 / 2 / 11 / 3;
  }
  .qr-code {
    grid-area: 9 / 3 / 11 / 4;
  }
}

.ad-upload-wrap {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .preview-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

input {
  background: transparent;
  border: 0;
  padding: 1rem;
}

textarea {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  padding: 1rem;
  font-size: 1.25rem;
  line-height: 1.2;
  border-radius: 0;

  // safari only
  @media not all and (min-resolution: 0.001dpcm) {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    margin: 1rem;
    padding: 0;
  }
}

.title {
  font-variant: normal;
  transform: scale(2);
  transform-origin: 0 0;
  width: 50%;
  height: 50%;

  // safari only

  @media not all and (min-resolution: 0.001dpcm) {
    width: calc(50% - 1rem);
    height: calc(50% - 1rem);
  }
}

.highlight-color {
  background: var(--ci-color) !important;
  color: var(--ci-color-contrast);
}

.secondary-color {
  background: #fcfcfc;
}

textarea {
  resize: none;
}

.qr-info {
  display: inline-block;
  padding: 20px;
}
</style>
