<template>
  <div class="generator">
    <div class="sub-wrap">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../assets/icons/dashboard.svg" />
            <h2>{{ $t("global.generator") }}</h2>
            <h3>{{ $t("global.generatorInfo") }}</h3>
          </div>
        </div>
        <AdGenerator></AdGenerator>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import AdGenerator from "@/components/AdGenerator";

export default {
  name: "generator",
  components: {
    AdGenerator,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scope>
@media (min-width: 1280px) {
  .generator .container {
    width: 1280px;
  }
}
</style>